import React from 'react'
import { ITaxAnalysisReportParams } from 'types'

interface Props {
}

interface Hook {
    params: ITaxAnalysisReportParams
    handleChangeParams: (key: string, value: any) => void
}

const useTaxAnalysisReportParams = (props?: Props): Hook => {
    const [params, setParams] = React.useState<ITaxAnalysisReportParams>({
        load_tax_exel: {
            file1: null,
            file2: null
        }
    })

    const handleChangeParams = (key: string, value: any) => {
        setParams(current => ({
            ...current,
            [key]: value
        }))
    }

    return {
        params,
        handleChangeParams
    }
}

export default useTaxAnalysisReportParams
