import { Box, Button, Icon, IconButton, Tooltip, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import FileUploadDialog from 'components/FileUploadDialog/FileUploadDialog'
import React from 'react'
import { ITaxAnalysisReportParams, IUploadDocumentFile } from 'types'


type ParamsType = "load_tax"

interface Props {
    type: ParamsType | string
    disabled: boolean
    reportKey: string
    onChange: (key: string, value: any) => void
    params: ITaxAnalysisReportParams
    clearFiles: boolean
}

type TypeFile = 'AP' | 'AR' | null


const TaxAnalisysReportParams = (props: Props) => {
    const { disabled, params, reportKey, onChange, clearFiles } = props
    const [open, setOpen] = React.useState<boolean>(false)
    const [typeFile, setTypeFile] = React.useState<TypeFile>(null)
    const [file1, setFile1] = React.useState<File | null>(null)
    const [file2, setFile2] = React.useState<File | null>(null)

    const existFileOne = React.useMemo(() => {
        return Boolean(file1)
    }, [file1])


    const hanldeClearFiles = () => {
        setFile1(null)
        setFile2(null)
    }

    React.useEffect(() => {
        if (clearFiles) {
            hanldeClearFiles()
        }
    }, [clearFiles])

    const existFileTwo = React.useMemo(() => {
        return Boolean(file2)
    }, [file2])

    const uploadExelConciliationFile = (acceptedFiles: IUploadDocumentFile[]) => {
        try {
            if (typeFile === 'AP') {
                const file1 = acceptedFiles[0].file
                onChange(reportKey, { ...params[reportKey], file1 })
                setFile1(file1)
            } else if (typeFile === 'AR') {
                const file2 = acceptedFiles[0].file
                onChange(reportKey, { ...params[reportKey], file2 })
                setFile2(file2)
            }
        } catch (error) {
            console.log("Error", error)
        }
    }

    const handleDeleteFile = (typeFile: TypeFile) => {

        const updatedParams = {
            ...params[reportKey],
            [typeFile === 'AP' ? 'file1' : 'file2']: null
        }

        if (typeFile === 'AP') {
            setFile1(null)
        } else {
            setFile2(null)
        }

        onChange(reportKey, updatedParams)
    }

    return (
        <div className="w-full flex flex-wrap pt-4">
            {
                reportKey === "load_tax_exel" &&
                <Box className="ml-1.5 pt-2">
                    {
                        !disabled && (
                            <Alert
                                severity="warning"
                                icon={<Icon fontSize='inherit'>warning</Icon>}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '1rem 0',
                                    width: 550,
                                    padding: '0.75rem 1rem',
                                }}
                            >
                                <AlertTitle>Nota</AlertTitle>
                                Se debe subir los dos archivos Excel de forma obligatoria, y en el
                                orden correspondiente.
                            </Alert>
                        )
                    }

                    <Box style={{ display: 'flex', gap: 7 }}>
                        {!existFileOne && (
                            <Button
                                disabled={disabled}
                                disableElevation
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={() => {
                                    setTypeFile('AP')
                                    setOpen(true)
                                }}
                                style={{ paddingLeft: "0.85rem", height: 35, width: 270 }}
                            >
                                {"Subir excel AP Charge"}
                                <Icon fontSize="small" className="ml-1">
                                    upload
                                </Icon>
                            </Button>
                        )}

                        {!existFileTwo && (
                            <Button
                                disabled={disabled}
                                disableElevation
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={() => {
                                    setTypeFile('AR')
                                    setOpen(true)
                                }}
                                style={{ paddingLeft: "0.85rem", height: 35, width: 270 }}
                            >
                                {"Subir excel AR Charge"}
                                <Icon fontSize="small" className="ml-1">
                                    upload
                                </Icon>
                            </Button>
                        )}
                    </Box>

                    <Box style={{
                        display: 'flex',
                        gap: 7,
                        paddingTop: 20,
                        width: 550,
                        flexDirection: 'column'
                    }}>
                        {existFileOne && (
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: 'space-between',
                                    padding: "0.5rem 1rem",
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    backgroundColor: "#f5f5f5",
                                    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    style={{
                                        marginRight: "0.75rem",
                                        color: "#333",
                                    }}
                                >
                                    <strong>Archivo AP seleccionado:</strong> {file1!.name}
                                </Typography>
                                <IconButton
                                    size="small"
                                    onClick={() => handleDeleteFile('AP')}
                                    style={{ color: "#ff6b6b" }}
                                >
                                    <Tooltip title="Borrar Archivo">
                                        <Icon fontSize="small">close</Icon>
                                    </Tooltip>
                                </IconButton>
                            </Box>
                        )}

                        {existFileTwo && (
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: 'space-between',
                                    padding: "0.5rem 1rem",
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    backgroundColor: "#f5f5f5",
                                    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    style={{
                                        marginRight: "0.75rem",
                                        color: "#333",
                                    }}
                                >
                                    <strong>Archivo AR seleccionado:</strong> {file2!.name}
                                </Typography>
                                <IconButton
                                    size="small"
                                    onClick={() => handleDeleteFile('AR')}
                                    style={{ color: "#ff6b6b" }}
                                >
                                    <Tooltip title="Borrar Archivo">
                                        <Icon fontSize="small">close</Icon>
                                    </Tooltip>
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                    <FileUploadDialog
                        open={open}
                        onClose={() => setOpen(false)}
                        onAccept={(files) => uploadExelConciliationFile(files)}
                        singleFile
                        hideDescription
                        validateTaxAnalisys
                    />

                </Box>
            }

        </div>
    )
}

export default TaxAnalisysReportParams
