import React from "react"
import { Button, Card, CardActions, CardContent, Paper, Snackbar, Typography } from "@material-ui/core"
import { FileUploadDialog, FixedLoadingIndicator } from "components"
import { AUXILIAR_TITLE, ROOT_TITLE, uploadAuxiliar, uploadAuxiliarNUEVO } from "lib"
import { Helmet } from "react-helmet"
import { IMessageConfig } from "types"
import { Alert } from "@material-ui/lab"

const Auxiliar = () => {
    const [openDialog, setOpenDialog] = React.useState<boolean>(false)
    const [openDialogNUEVO, setOpenDialogNUEVO] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })

    const handleUpload = async (file: File) => {
        try {
            setLoading(true)
            await uploadAuxiliar(file)
            setLoading(false)
            setOpenDialog(false)
            setMessageConfig({ open: true, message: "El archivo cargado será procesado a continuación.", severity: "info" })
        } catch (error) {
            setLoading(false)
            setOpenDialog(false)
            setMessageConfig({ open: true, message: "El archivo cargado no pudo ser procesado.", severity: "error" })
            console.log(error)
        }
    }

    const handleUploadNUEVO = async (file: File) => {
        try {
            setLoading(true)
            await uploadAuxiliarNUEVO(file)
            setLoading(false)
            setOpenDialogNUEVO(false)
            setMessageConfig({ open: true, message: "El archivo cargado será procesado a continuación.", severity: "info" })
        } catch (error) {
            setLoading(false)
            setOpenDialogNUEVO(false)
            setMessageConfig({ open: true, message: "El archivo cargado no pudo ser procesado.", severity: "error" })
            console.log(error)
        }
    }

    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${AUXILIAR_TITLE}`}</title>
            </Helmet>
            <Card variant="outlined" style={{ margin: 2, border: '1px solid #b5b5b5' }}>
                <CardContent>
                    <Typography variant="h5" component="div">
                        Archivo Auxiliar (Formato Actual)
                    </Typography>
                    <Typography variant="body2">
                        Aquí puedes subir el achivo auxiliar necesario para el proceso. Asegúrate de que el archivo cumpla con el formato requerido.
                    </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: 'center', paddingBottom:'20px' }}>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => setOpenDialog(true)}
                    >
                        Subir Archivo Actual
                    </Button>
                </CardActions>
            </Card>
            <Card variant="outlined" style={{ margin: 2, border: '1px solid #b5b5b5', marginTop: '30px' }}>
                <CardContent>
                    <Typography variant="h5" component="div">
                        Archivo Auxiliar (Formato Nuevo)
                    </Typography>
                    <Typography variant="body2">
                        Aquí puedes subir el achivo auxiliar necesario para el proceso. Asegúrate de que el archivo cumpla con el formato requerido.
                    </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: 'center', paddingBottom:'20px' }}>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => setOpenDialogNUEVO(true)}
                    >
                        Subir Archivo Nuevo
                    </Button>
                </CardActions>
            </Card>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading} />
            <FileUploadDialog
                open={openDialog}
                onClose={() => { setOpenDialog(false) }}
                onAccept={(files) => { handleUpload(files[0].file) }}
                singleFile
                hideDescription
            />
            <FileUploadDialog
                open={openDialogNUEVO}
                onClose={() => { setOpenDialogNUEVO(false) }}
                onAccept={(files) => { handleUploadNUEVO(files[0].file) }}
                singleFile
                hideDescription
            />
        </Paper>
    )
}

export default Auxiliar