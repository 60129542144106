import axios, { AxiosError } from 'axios'
import { ISSUED_PATH, REJECTED_PATH, REPORT_PATH, SEND_BY_EMAIL_PATH, SEND_REJECT_REPORT_EMAIL_PATH } from 'lib'
import { CONFIGURATION_API_SERVICE_DOMAIN, DOCUMENTS_API_SERVICE_DOMAIN } from 'lib/constants/api'

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })

const intanceConfig = axios.create({ baseURL: CONFIGURATION_API_SERVICE_DOMAIN })

export const sendDocumentReportByEmailService = async (params: any) => {
    try {
        const result = await intance.post(REPORT_PATH + SEND_BY_EMAIL_PATH, params)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const sendDocumentReportSuppliers = async (params: any) => {
    try {
        const result = await intanceConfig.post('/supplier-report', { emails: params })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const sendTaxAnalysisReportByEmailService = async (formData:FormData) => {
    try {
        const result = await intance.post('/ar-ap-report',formData)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const sendRejectedReportByEmailService = async (params: any) => {
    try {
        const result = await intance.post(REJECTED_PATH + SEND_REJECT_REPORT_EMAIL_PATH, params)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const sendRetentionReportByEmailService = async (params: any) => {
    try {
        console.log('REPORTES',params)
        const result = await intance.post(REPORT_PATH + ISSUED_PATH, params)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}